import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Section } from "../../Core";
import image1 from './../../../assets/image/rebrand/png/youfibre_you_what.png'
import icon1 from './../../../assets/image/rebrand/svg/youmesh-icon.svg'

const YouMesh = (props) => {
    return (
        <Section className="green-bg youmesh">
            <Container>
                <Row>
                    <Col sm={12}>
                        <h2>YOUMESH. YOU WHAT?</h2>
                    </Col>
                    <Col sm={12} lg={6}>
                        <img src={image1} className="w-100 img-fit ratio-1 pb-5"/>
                    </Col>
                    <Col sm={12} lg={6} className="flex flex-column justify-between h-100">
                       <p className="fw-bold">
                            <b>YOUMESH improves Wi-Fi coverage in every room.</b>
                        </p>
                        <p>
                            Wall to wall, top to bottom, in every nook, every cranny. If you can’t get a signal with YOUMESH, your phone (or whatever device) is dead. RIP.
                        </p>
                        <>
                            <hr />
                            <Row>
                                <Col xs={3} md={2} lg={2} className="flex align-items-start pt-3">
                                    <img src={icon1} className="w-100" />
                                </Col>
                                <Col xs={6} md={7} lg={8} className="text-left justify-center">
                                    <h5>YOUMESH</h5>
                                    <span>Boost Wi-Fi coverage in every room of your house! Perfect for our You150 and You1000 packages. in every room!</span>
                                </Col>
                                <Col xs={3} md={3} lg={2} className="text-left justify-start pt-0 text-center">
                                    <h5>£7</h5>
                                    <small>per&nbsp;month,<br />per&nbsp;unit</small>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col xs={3} md={2} lg={2} className="flex align-items-start pt-3">
                                    <span className="notify-badge icoPro dark-bg">PRO</span>
                                    <img src={icon1} className="w-100" />
                                </Col>
                                <Col xs={6} md={7} lg={8} className="text-left justify-center">
                                    <h5>YOUMESH PRO</h5>
                                    <span>Get boosted whole-house coverage just like our YouMesh. The YouMesh Pro is designed specifically for our You2000 and You8000 packages to handle the extra speeds.</span>
                                </Col>
                                <Col xs={3} md={3} lg={2} className="text-left justify-start pt-0 text-center">
                                    <h5>£14</h5>
                                    <small>per&nbsp;month,<br />per&nbsp;unit</small>
                                </Col>
                            </Row>
                            <hr />
                        </>
                    </Col>
                </Row>
            </Container>
        </Section>
    )
};

export default YouMesh;
