import React, { createRef, useEffect } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import CheckAvailability from './../../../components/Rebrand/CheckAvailability/CheckAvailability';
import youfibre_banner_01 from './../../../assets/image/rebrand/jpg/youfibre_01.jpg';
import youfibre_banner_02 from './../../../assets/image/rebrand/jpg/youfibre_02.jpg';
import youfibre_banner_03 from './../../../assets/image/rebrand/jpg/youfibre_03.jpg';
import youfibre_banner_04 from './../../../assets/image/rebrand/jpg/youfibre_04.jpg';
import TrustBoxWidget from '../../TrustBoxWidget/TrustBoxWidget';

import lottie from "lottie-web"
import desktopAnimation from "../../../assets/animations/desktop.json"
import mobileAnimation from "../../../assets/animations/mobile.json"
import snailAnimation from "../../../assets/animations/snail-animation.json"
import cloudsAnimation from "../../../assets/animations/clouds.json"


import trustpilot from './../../../assets/image/rebrand/svg/trustpilot.svg';

import { Text, PageTitle, Section } from "../../Core";
import styled from "styled-components";
import { position } from 'polished';

const animationContainer = createRef()
const animationContainerMob = createRef()
const snailAnimationContainer = createRef()
const cloudsAnimationContainer = createRef()

const SnailAnimationSection = styled.div`
  width: 2100px !important;
  @media (min-width: 2099px) {
    width: 2800px !important;
  }
`

const StyledText = styled(Text)`
  @media (max-width: 1320px) { 
    font-size: 1.3rem !important;
  }
  @media (max-width: 991px) {
    text-align: center;
  }
`

const HeroLanding = (props) => {

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: desktopAnimation
    });

    const anim1 = lottie.loadAnimation({
      container: animationContainerMob.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: mobileAnimation
    });

    const anim2 = lottie.loadAnimation({
      container: snailAnimationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: snailAnimation
    });

    const anim3 = lottie.loadAnimation({
      container: cloudsAnimationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: cloudsAnimation
    });
  }, [])

  return (
    <>
      <Section className="green-bg pt-0 pb-0">
        <div
          id="animation-container"
          ref={animationContainer}
          className="desktop-visible-image bf-image"
        />
        <Row className="justify-content-md-center animations-wrapper">
          <Col lg="4" className='clouds-container'>
            <div
              id="animation-container"
              ref={cloudsAnimationContainer}
              className="mobile-visible-image bf-image"
            />
          </Col>
          <Col lg="4" className='text-center align-self-center half-width-middle'>
            <h1 className="darkColor small-title">
              <i>Turbo</i>&nbsp;-charged
            </h1>
            <h1 className="whiteColor small-title" style={{ marginLeft: '-3px' }}>Broadband</h1>
            <StyledText
              color="dark"
              fontWeight={700}
              fontSize="1.4rem"
              marginTop="1rem"
            ><b>150 Mbps Full-Fibre Broadband just £23.99/month</b></StyledText>
            <Row>
              <Col className='mt-xl-4 pl-0 pr-0 text-center centered'>
                <CheckAvailability
                  focusInput={false}
                  hideLogo={true}
                  customTitle={""}
                  customSubtitle={""}
                />  
              </Col>
            </Row>
            <img src={trustpilot} alt="Trustpilot" className="trustpilot-logo mt-xl-4" />
          </Col>
          <Col lg={4} className='half-width-middle'>
            <div style={{ position: 'relative' }} className="homepage-special-offer">
              <div
                id="animation-container"
                ref={animationContainerMob}
                className="mobile-visible-image bf-image"
              />
            </div>
          </Col>
        </Row>
      </Section>
      <SnailAnimationSection>
        <div
          id="animation-container"
          ref={snailAnimationContainer}
        />
      </SnailAnimationSection>
    </>
  );
};

export default HeroLanding;
