import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Text, Button } from "../../components/Core";
import { navigate } from "gatsby";
import axios from 'axios';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import TrustPilotSvg from './../../assets/image/rebrand/png/trustpilot-stars.png';
import trustpilot5 from './../../assets/image/rebrand/svg/trustpilot-5.svg';

const TrustBoxCarousel = () => {
    const [trustpilotReviews, setTrustpilotReviews] = useState(null);
    const [trustScore, setTrustScore] = useState(5);

    const responsive = {
        0: { items: 1 },
        768: { items: 2 },
        992: { items: 3 },
        1200: { items: 4 },
    };

    useEffect(() => {
        const businessUnitId = "5f4153095882480001d60ab4";
        axios.get(`https://widget.trustpilot.com/trustbox-data/53aa8912dec7e10d38f59f36?businessUnitId=${businessUnitId}&locale=en-GB&reviewLanguages=en&reviewStars=4%2C5&includeReviews=true&reviewsPerPage=6`)
            .then(function (response) {
                let data = response.data;
                setTrustpilotReviews(data);
                setTrustScore(data.businessEntity.trustScore);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    }, []);
    return (<>
        {trustpilotReviews &&
            <Section className="trustbox dark-bg">
                <Container>
                    <Row>
                        <Col lg="12" xl="12">
                            <div className="pb-5">
                                <img src={TrustPilotSvg} className="trustpilot pb-5" />
                                <h2>Thanks to you, we've got {trustScore} stars</h2>
                                <p>We’re ranking well ahead of the usual suspects. <span onClick={() => navigate('/reviews/')}>Check out our reviews here.</span></p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12" xl="12">
                            <AliceCarousel
                                mouseTracking
                                responsive={responsive}
                                disableButtonsControls={true}
                                autoPlay={true}
                                autoPlayDirection={'ltr'}
                                autoPlayStrategy={'all'}
                                autoPlayInterval={4000}
                                infinite={true}
                                controlsStrategy="alternate">
                                {trustpilotReviews.reviews.map((item, index) =>
                                    <>
                                        <div className='tp_title mb-4'>
                                            <h4 key={index}>{item.title}</h4>
                                        </div>
                                        <img src={trustpilot5} className='tp_img mb-3'/>
                                        <div className='tp_content'>
                                            <p>{item.text}</p>
                                        </div>
                                        <span>{item.consumer.displayName}</span>
                                    </>)
                                }
                            </AliceCarousel>
                            <Button onClick={() => navigate('/reviews/')} className="medium-button blue-bg seeAll-button">See all reviews</Button>
                        </Col>
                    </Row>
                </Container>
            </Section>
        }
    </>);
};
export default TrustBoxCarousel;
