import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import CheckAvailability from '../CheckAvailability/CheckAvailability';
import mobileImage from './../../../assets/image/rebrand/svg/price-fix-mobile.svg';
import desktopImage from './../../../assets/image/rebrand/svg/price-fix-desktop.svg';

import arrowHero from './../../../assets/image/rebrand/svg/arrows_homehero.svg';

import TrustBoxWidget from '../../TrustBoxWidget/TrustBoxWidget';

const Background = styled.div`
  background-color: #08DE9E;
  overflow-x: clip;

  & img {
    position: relative;
    margin: 0 auto;
  }

  & .cover {
    position: relative;
  }
`;

const Arrow = styled.img`
  position: absolute !important;
  width: auto;
  height: 100%;1
  top: 0vh;
  right: 43vw;
  z-index: 0;
  animation: homeHero 9s linear infinite;
  animation-delay: 2s;

  @media (max-width: 1200px) {
    animation: homeHero 8s linear infinite;
  }
  @media (max-width: 992px) {
    animation: homeHero 7s linear infinite;
  }
  @media (max-width: 767px) {
    animation: homeHero 6s linear infinite;
  }
  @media (max-width: 576px) {
    animation: homeHero 5s linear infinite;
  }
`;

const HeroLandingPriceFix = () => {
  return (
    <Background>
    <Col className="homepage ">
      <Arrow src={arrowHero} />
      <Row className="justify-content-md-center cover">
        <Col xs={9} className="d-block d-md-none text-center m-auto pt-5">
          <img
            src={mobileImage}
            width={'100%'}
            className='mt-m-5'
          />
        </Col>
      </Row>

      <Row className="justify-content-md-center cover">
        <Col xs={8} md={5} className="d-none d-md-block text-center mt-5">
          <img
            src={desktopImage}
            width={'70%'}
            className="d-lg-block"
          />
        </Col>
      </Row>

      <Row className="justify-content-md-center mt-0 cover">
        <Col lg={8} md={8} sm={12}>
          <p className="text-center largest-font mt-4"><i>Ultra</i>fast broadband from <b>£23.99/month</b> - fixed for 18 months.</p>
        </Col>
        <Col lg={6} md={8} sm={12}>
          <CheckAvailability center />
        </Col>
      </Row>

      <Row className="justify-content-md-center mt-4 dark-bg cover">
        <Col lg={8} md={12} sm={12} className="mb-4 mt-1">
          <TrustBoxWidget />
        </Col>
      </Row>
    </Col>
    </Background>
  );
};

export default HeroLandingPriceFix;
